import { template as template_1ac882ae58654af8b915a476982d7451 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1ac882ae58654af8b915a476982d7451(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
